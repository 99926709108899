<template>
  <div class="popover-main">
    <div class="popover-main-top">
      <div>车牌：{{positionInfo.id}}</div>
      <i class="iconfont iconzhankai curp" @click="click" :class="{'rotate90': isShow}"></i>
    </div>
    <el-collapse-transition>
      <div v-show="isShow" class="popover-content">
        <div class="popover-content-cell">
          <span>上报时间</span>
          <span>{{positionInfo.time}}</span>
        </div>
        <div class="popover-content-cell">
          <span>速度</span>
          <span>{{positionInfo.speed}}</span>
        </div>
        <div class="popover-content-cell">
          <span>方向</span>
          <span>{{positionInfo.directionDesc}}</span>
        </div>
        <div class="popover-content-cell">
          <span>位置</span>
          <span class="text-style">{{positionInfo.location}}</span>
        </div>
        <div class="popover-content-cell">
          <span>总里程</span>
          <span>{{positionInfo.mileage}}</span>
        </div>
        <div class="popover-content-cell">
          <span>高度</span>
          <span>{{positionInfo.altitude}}</span>
        </div>
        <div class="popover-content-cell">
          <span>状态</span>
          <span>{{positionInfo.deviceSituation}}</span>
        </div>
        <div class="popover-content-cell">
          <span>设备报警</span>
          <span style="color: #FF0000;" class="text-style">{{positionInfo.deviceAlarm}}</span>
        </div>
        <div class="popover-content-cell">
          <span>设备号</span>
          <span>{{positionInfo.deviceName}}</span>
        </div>
        <div class="popover-content-cell">
          <span>订单号</span>
          <span style="display: inline-block; width: 140px">{{positionInfo.orderId}}</span>
          <span style="color: #0575E6" class="curp" @click="clickViewOrder">查看订单</span>
        </div>
        <div class="popover-content-cell">
          <span>运输路线</span>
          <span class="text-style">{{positionInfo.transportRoute}}</span>
        </div>
        <div class="popover-content-cell">
          <span>品名</span>
          <span>{{positionInfo.category}}</span>
        </div>
        <div class="popover-content-cell">
          <span>重量</span>
          <span>{{positionInfo.categoryWeight}}</span>
        </div>
        <div class="popover-content-icon-list">
          <span class="curp" title="实时视频" @click="clickReal(positionInfo)">
            <i class="iconfont iconshishishipin"></i>
          </span>
          <span class="curp" title="实时抓拍">
            <i class="iconfont iconpaizhao"></i>
          </span>
          <span class="curp" title="实时对讲">
            <i class="iconfont iconduijiangji"></i>
          </span>
          <span class="curp" title="实时监听">
            <i class="iconfont iconjianting"></i>
          </span>
          <span class="curp" @click="clickTts" title="语音播报">
            <i class="iconfont" style="font-size: 12px;font-weight: bold">TTS</i>
          </span>
          <span class="curp" title="历史轨迹" @click="jump('trajectory')">
            <i class="iconfont iconlishiguiji"></i>
          </span>
          <span class="curp" title="历史视频" @click="jump('video')">
            <i class="iconfont iconlishishipin"></i>
          </span>
          <span class="curp" title="设备重启" @click="reStart()">
            <i class="iconfont iconshebeichongqi"></i>
          </span>
        </div>
      </div>
    </el-collapse-transition>
    <!---->
    <el-dialog title="TTS语音播报" :visible.sync="ttsShow" width="596px" :modal-append-to-body="false">
      <el-input
              type="textarea"
              :rows="2"
              placeholder="请填写20字以内的语音播报内容······"
              maxlength="20"
              v-model="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitTTs">确 定</el-button>
        <el-button @click="ttsShow = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      positionInfo: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data () {
      const vm = this
      return {
        isShow: false,
        ttsShow: false,
        textarea: ''
      }
    },
    watch: {
      ttsShow: function () {
        if (!this.ttsShow) {
          this.textarea = ''
        }
      }
    },
    methods: {
      async submitTTs () {
        if (!this.textarea) {
          this.$message.warning('请输入内容')
          return
        }
        try {
          await this.$api.DownMsg({
            deviceId: this.positionInfo.deviceId,
            text: this.textarea,
            cmd: 12
          })
          this.$notify({
            title: '成功',
            message: '发送成功',
            type: 'success'
          })
          this.ttsShow = false
          this.textarea = ''
        } catch (e) {
          console.log(e)
        }
      },
      clickReal (val) {
        console.log(val)
        this.$router.push({
          path: '/safety-center/safety-monitoring/components/real-time-play-video',
          query: {
            plateNumber: this.positionInfo.id,
            deviceId: this.positionInfo.deviceId,
          }
        })
      },
      click () {
        if (JSON.stringify(this.positionInfo) === '{}') return
        this.isShow = !this.isShow
      },
      clickViewOrder () {
        if (!this.positionInfo.orderId || this.positionInfo.orderId.length < 15) return
        this.$router.push({
          path: '/search-order/order-details',
          query: {
            orderId: this.positionInfo.orderId
          }
        })
      },
      jump (value) {
        if (!this.isClick(this.positionInfo.deviceStatusNum) && value === 'video') {
          this.isMessage(this.positionInfo.deviceStatus, '历史视频')
          return
        }
        // console.log(this.positionInfo)
        this.$router.push({
          path: '/safety-center/historical-playback',
          query: {
            activeName: value,
            deviceId: this.positionInfo.deviceId,
            deviceName: this.positionInfo.deviceName
          }
        })
      },
      isClick (v) {
        let isClick = false
        v === 0 || v === 1 ? isClick = true : isClick = false
        return isClick
      },
      isMessage (status, mes) {
        this.$message.warning(`车辆设备处于${status}状态，无法使用${mes}`)
      },
      clickTts () {
        if (!this.isClick(this.positionInfo.deviceStatusNum)) {
          this.isMessage(this.positionInfo.deviceStatus, '语音播报')
          return
        }
        this.ttsShow = true
      },
      async reStart(){
        try {
          await this.$api.reStart({
            deviceId: this.positionInfo.deviceId,
          })
          this.$notify({
            title: '成功',
            message: '设备正在重启，请稍后刷新',
            type: 'success'
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>

<style lang='sass' scoped>
  .popover-main
    width: 333px
    display: inline-block
    cursor: initial

    &-top
      position: relative
      z-index: 6
      display: flex
      height: 31px
      padding: 0 11px
      color: #333333
      align-items: center
      justify-content: space-between
      background: rgba(238, 238, 238, 1)
      box-shadow: 0 1px 5px 0 rgba(8, 88, 169, 0.3)
      border-radius: 2px 2px 0 0

      i
        color: $-font-color-2
        transform: rotate(270deg)
        transition: transform .35s

      .rotate90
        transform: rotate(90deg)

    .popover-content
      position: relative
      z-index: 5
      background-color: #fff
      padding: 14px 11px 16px

      .popover-content-cell
        margin-bottom: 10px

        span:nth-child(1)
          width: 80px
          display: inline-block
          color: $-font-color-2

        span:nth-child(2)
          color: $-font-color-1

        .text-style
          color: #FF0000
          display: inline-block
          width: calc(100% - 80px)
          vertical-align: top

    .popover-content-icon-list
      padding-top: 12px
      border-top: 1px solid $grayShallow3

      span
        display: inline-block
        background-color: #EDEDED
        border-radius: 50%
        width: 30px
        height: 30px
        text-align: center
        line-height: 30px
        transition: .35s
        vertical-align: top

        & + span
          margin-left: 10px

        &:hover
          background-color: $-color-primary-2

          i
            color: #ffffff

        i
          color: #999999
          font-size: 22px
</style>
