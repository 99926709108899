<template>
  <div class="video-content">
    <div class="main-video">
      <div class="video" v-loading="videoLoading">
        <ayl-flv v-for="(item, idx) in allVideoUrl" :key="idx" :style="mainStyle" :url='item'></ayl-flv>
        <!-- <ayl-rtmp v-for="(item, idx) in allVideoUrl" :key="idx" :style="mainStyle" :url="item" ref="rtmp"/> -->
      </div>
      <div class="video-operation">
        <div>
          <el-select v-model="channel" @change="paChange" style="width: 120px">
            <el-option v-for="(PaItem, PaIdx) in $enums.VideoPerspective.list" :key="PaIdx" :label="PaItem.label" :value="PaItem.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <i class="iconfont curp" v-for="(item, idx) in iconList"
             :class="[item.name, screenNum===item.value?'active-icon': '']"
             :key="idx"
             @click="clickSwitch(item.value)"></i>
        </div>
        <!-- <div>
          <span v-for="(item, idx) in operationList" :key="idx" class="operation-item curp">{{item}}</span>
        </div> -->
      </div>
    </div>
    <div class="main-map" id="container">
      <div class="map-popover">
        <car-info-popover :positionInfo="mapPosition[0]" ref="carInfoPopover"></car-info-popover>
      </div>
    </div>
  </div>
</template>

<script>
  import CarInfoPopover from './car-Info-Popover'
  import AylRtmp from './AylRtmp'

  const LineIcon = require('../../../../assets/images/map-icon (1).png')
  const offLineIcon = require('../../../../assets/images/map-icon (2).png')
  const onLineIcon = require('../../../../assets/images/map-icon (3).png')

  export default {
    components: {
      CarInfoPopover,
      AylRtmp
    },
    props: {
      carList: {
        type: Array,
        default: function () {
          return []
        },
      }
    },
    data () {
      return {
        iconList: [{
          name: 'iconicon-test2',
          value: 1
        }, {
          name: 'iconicon-test3',
          value: 4
        }, 
        // {
        //   name: 'iconicon-test9',
        //   value: 9
        // }
        ],
        operationList: ['字幕', '云台', '色彩', '比例', '画质', '音量', '设置'],
        // 地图Data
        map: null,
        mapCenter: null,
        mapMarker: null,
        mapPosition: [],
        // 视频数据
        screenNum: 4,
        allVideoUrl: [],
        channel: 1,
        videoLoading: false,
      }
    },
    computed: {
      mainStyle () {
        if (this.screenNum === 4) return 'width: calc(100% / 2);height:calc(100% / 2)'
        if (this.screenNum === 9) return 'width: calc(100% / 3);height:calc(100% / 3)'
        return 'width: 100%;height:100%'
      }
    },
    watch: {
      carList: {
        async handler (newName) {
          this.allVideoUrl = []
          // 把数据都清空
          if (newName.length === 0) {
            this.map.remove(this.mapMarker)
            this.mapPosition = []
            this.$refs.carInfoPopover.isShow = false
            return
          }
          // 调用位置方法
          await this.triggerData()
          // 调用视频
          await this.playVideo(1)
        }
      }
    },
    methods: {
      // 初始化地图
      initAmap () {
        this.map = new AMap.Map('container', {
          center: this.mapCenter,
          resizeEnable: true,
          zoom: 10,
        })
      },
      // 地图操作
      async triggerData () {
        // 请求车辆位置信息
        this.mapPosition = await this.$api.GetRealTimeCarLocation({
          deviceIds: [this.carList[0].device]
        })
        // 有先清除
        if (this.mapMarker) {
          this.map.remove(this.mapMarker)
        }
        // 设置点标记
        this.mapMarker = new AMap.Marker({
          map: this.map,
          position: this.mapPosition[0].position,
          icon: offLineIcon,
          offset: new AMap.Pixel(-26, -24),
          autoRotation: false,
          angle: Number(this.mapPosition[0].direction),
        })
        this.$refs.carInfoPopover.isShow = true
        this.map.setFitView()
        // this.map.panBy(0, 250)
      },
      // 切换屏幕
      clickSwitch (v) {
        if (this.allVideoUrl.length === 0) return
        if (v === this.screenNum) return
        this.allVideoUrl = []
        this.screenNum = v
        this.playVideo()
      },
      async paChange(value) {
        console.log(this.carList)
        this.videoLoading = true
        setTimeout(() => {
          this.playVideo(value)
        }, 1000);
        // let playingFlag = await this.$api.commonService_getVideoPlayingFlag({
        //   deviceName: this.carList[0].deviceName
        // })
        // if(playingFlag.playAble === false) {
        //   this.$message({
        //     message: `${playingFlag.userName}用户正在观看当前设备的视频，请稍后再试。`,
        //     type: 'warning'
        //   });
        //   return
        // } else {
        //   await this.$api.commonService_setVideoPlayingFlag({
        //     deviceName: this.carList[0].deviceName
        //   });
        //   setTimeout(() => {
        //     this.playVideo(value)
        //   }, 1000);
        // }
        this.videoLoading = false
      },
      async playVideo (val) {  
        if(this.carList.length == 0) {
          return
        } else {
          let _allVideoUrl = await this.$api.getRtmpUrls({
            deviceId: this.carList[0].device,
            // channelList: [val],
            channelList: this.screenNum === 1 ? [val] :  Array.from({length: this.screenNum}, (v, i) => i + 1),
            dataType: 0,
            streamType: 0,
            tenantId: this.carList[0].tenantId
          })
          this.allVideoUrl = Object.keys(_allVideoUrl).map((k) => _allVideoUrl[k])
          // console.log(this.allVideoUrl)
        }     
      },
    },
    mounted () {
      this.initAmap()
    },
    async activated () {
      // this.allVideoUrl = []
      // 调用视频
      // await this.playVideo()
    },
  }
</script>

<style lang='sass' scoped>
  .video-content
    height: 100%
    width: 100%

    & > div
      display: inline-block
      height: 100%
      vertical-align: top

    .main-video
      width: calc(100% - 329px)

      .video
        height: calc(100% - 44px)
        background-color: #000

      .video-operation
        padding: 0 10px 0 20px
        height: 44px
        display: flex
        align-items: center
        justify-content: space-between

        i
          color: $grayShallow2
          margin-right: 10px

        .active-icon
          color: $-color-primary-2

        .operation-item
          display: inline-block
          width: 42px
          height: 20px
          border-radius: 10px
          font-size: 12px
          text-align: center
          line-height: 20px
          border: 1px solid $grayShallow2
          transition: .35s

          & + .operation-item
            margin-left: 10px

          &:hover
            border-color: $-color-primary-2
            @extend .active-icon

    .main-map
      width: 329px
      position: relative

      .map-popover
        position: absolute
        z-index: 2
        top: 12px
        right: 12px
</style>
